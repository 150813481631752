// Libraries

import React, { useState } from "react";

// Private

const ratingNames = [
  "fahrzeugleistung",
  "reichweite",
  "komfort",
  "sicherheit",
  "laden",
  "innenraum",
  "technologie",
];

// Public

const Reviews = ({ answerText, userName, created, review }) => {
  const [showFullText, setShowFullText] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const handleReadMore = () => {
    setShowFullText(true);
  };

  const handleImageClick = () => {
    setIsImageModalOpen(true);
  };

  const onImagesModeClose = () => {
    setIsImageModalOpen(false);
  };

  return (
    <div>
      <div className="p-8 border border-gray-200 mt-4">
        <div className="grid grid-col-1 lg:grid-cols-3 gap-2">
          {ratingNames.map((rating) => (
            <>
              <div className="flex">
                <span className="text-yellow-600">★</span>
                <div className="ml-1">{review[rating].toFixed(1)}</div>
                <p className="ml-3 capitalize">{rating}</p>
              </div>
            </>
          ))}
        </div>
        <div className="text-sm text-gray-600 mt-2 font-bold mb-4">
          {userName} - {new Date(created).toLocaleDateString()}
        </div>
        <div className="p-3 bg-[#eee] mb-5 flex">
          <p className="text-sm mr-4">Jahr: {review.modelYear}</p>
          <div className="min-h-[1em] w-px self-stretch bg-gradient-to-tr from-transparent via-neutral-500 to-transparent opacity-50 dark:via-neutral-400 bg-[#d5d5d5]"></div>
          {review.subModel &&
            review.subModel !== "" &&
            review.isSubModelSelected === 0 && (
              <>
                <p className="text-sm mr-4 ml-4">
                  Untermodell: {review.subModel}
                </p>
                <div className="min-h-[1em] w-px self-stretch bg-gradient-to-tr from-transparent via-neutral-500 to-transparent opacity-50 dark:via-neutral-400 bg-[#d5d5d5]"></div>
              </>
            )}
          <p className="text-sm ml-4">
            Verkaufsstelle (POS): {review.verkaufsstelle}
          </p>
        </div>
        <div className="mt-2 text-sm text-gray-800">
          {showFullText || answerText.length <= 250
            ? answerText
            : `${answerText.slice(0, 250)}...`}
          {!showFullText && answerText.length > 250 && (
            <button
              onClick={handleReadMore}
              className="text-blue-500 ml-2 hover:underline"
            >
              Read more
            </button>
          )}
        </div>
        {review.answerA && review.answerA !== "" && (
          <div className="mt-3">
            <p className="text-sm font-medium">
              Was gefällt Ihnen an diesem Auto?
            </p>
            <p className="text-sm text-gray-600">{review.answerA}</p>
          </div>
        )}
        {review.answerB && review.answerB !== "" && (
          <div className="mt-2">
            <p className="text-sm font-medium">
              Was gefällt Ihnen an diesem Auto nicht?
            </p>
            <p className="text-sm text-gray-600">{review.answerB}</p>
          </div>
        )}
        {review.answerD && review.answerD !== "" && (
          <div className="mt-2">
            <p className="text-sm font-medium">
              Erfahrungen mit dem Kaufprozess.
            </p>
            <p className="text-sm text-gray-600">{review.answerD}</p>
          </div>
        )}
        {review.imageUrl && review.imageUrl.length > 0 && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mt-3">
            {review.imageUrl.map((image, index) => {
              return (
                <div className="border-black p-1">
                  <img
                    key={index}
                    src={image}
                    className="w-full h-full object-cover cursor-pointer"
                    alt="Review Image"
                    onClick={handleImageClick}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Reviews;
