import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useToast } from '../../../components/toast/use-toast.tsx';
import { AuthContext } from '../../../context/AuthContext.js';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../components/tabs/index.tsx';
import Slides from './components/slides.js';
import MobileSlides from './components/mobileSlides.js';
import General from './components/general.js';
import VendorFaqs from './components/faqs.js';

const EditProfile = () => {
  const { companyId, companyName } = useParams();
  const { toast } = useToast();
  const [isUploading, setIsUploading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    companyLogo: null,
    companyThumbnail: null,
    pageText: '',
  });
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchVendorDetails = async () => {
      try {
        const response = await fetch(`https://evmainbackend.theevshowroom.com/api/getVendorDetails/${companyId}`, {
          headers: {
            Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
            token: user?.token,
          },
        });
        const data = await response.json();

        if (data.status && data.data) {
          setInitialValues((prevValues) => ({
            ...prevValues,
            pageText: data.data.pageText || '', // Populate pageText from API
          }));
        } else {
          toast({
            title: 'Error',
            description: data.message || 'Failed to fetch vendor details',
            status: 'error',
          });
        }
      } catch (error) {
        console.error('Error fetching vendor details:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch vendor details',
          status: 'error',
        });
      }
    };

    fetchVendorDetails();
  }, [companyId, user?.token, toast]);

  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append('companyLogo', values.companyLogo);
    formData.append('companyThumbnail', values.companyThumbnail);
    formData.append('pageText', values.pageText);
    formData.append('companyId', companyId);

    setIsUploading(true);
    try {
      const response = await fetch('https://evmainbackend.theevshowroom.com/api/editVendorProfile', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          token: user?.token,
        },
      });
      const data = await response.json();

      if (response.ok) {
        toast({
          title: 'Profile Updated',
          description: 'Company profile updated successfully',
        });
      } else {
        throw new Error(data.message || 'Profile update failed');
      }
      setIsUploading(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      setIsUploading(false);
      toast({
        title: 'Error',
        description: 'Profile update failed',
        status: 'error',
      });
    }
    setSubmitting(false);
  };

  return (
    <div className='p-4 w-full  mx-auto'>
      <h2 className='text-3xl font-bold mb-6 text-center'>Edit Profile</h2>
      <Tabs defaultValue="general" className="max-w-full">
        <TabsList>
          <TabsTrigger value="general">General</TabsTrigger>
          <TabsTrigger value="desktop-slides">Desktop Slides</TabsTrigger>
          <TabsTrigger value="mobile-slides">Mobile Slides</TabsTrigger>
          <TabsTrigger value="faqs">FAQs</TabsTrigger>
        </TabsList>
        <TabsContent value="general">
          <General isUploading={isUploading} initialValues={initialValues} handleSubmit={handleSubmit} />
        </TabsContent>
        <TabsContent value="desktop-slides">
          <Slides companyName={companyName} vendorId={companyId} />
        </TabsContent>
        <TabsContent value="mobile-slides">
          <MobileSlides companyName={companyName} vendorId={companyId} />
        </TabsContent>
        <TabsContent value="faqs">
          <VendorFaqs vendorId={companyId} vendorName={companyName} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default EditProfile;
