// Libraries

import React, { useEffect, useState } from "react";

// Dependencies

import ReviewsModal from "./components/ReviewModel";
import { useParams } from "react-router-dom";
import { getReviewsByVendor } from "./components/utils";
import { ApprovalStatus } from "../../../constant/approvalStatus";

// Public

const Reviews = () => {
  const { companyName } = useParams();
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [reviewDetails, setReviewDetails] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);

  useEffect(() => {
    getReviewsByVendor(companyName, setReviewDetails);
  }, []);

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold py-4">Reviews</h2>
      <div className="overflow-auto">
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Customer Name
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Model Name
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Review Date
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Review Status
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {reviewDetails &&
              reviewDetails.length > 0 &&
              reviewDetails.map((review, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 border-b border-gray-200 text-sm">
                    {review.fullName}
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-sm">
                    {review.model}
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-sm">
                    {new Date(review.created).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 border-b border-gray-200 text-sm">
                    {review.approvalStatus === ApprovalStatus.APPROVED
                      ? "Approved"
                      : review.approvalStatus === ApprovalStatus.PENDING
                      ? "Pending"
                      : "Rejected"}
                  </td>
                  <td className="px-6 py-2 border-b border-gray-200 text-sm capitalize">
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedReview(review);
                        setIsReviewModalOpen(true);
                      }}
                      className="p-2 border border-black bg-black rounded-md ml-2 text-white"
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {isReviewModalOpen && (
          <ReviewsModal
            setIsReviewModalOpen={setIsReviewModalOpen}
            selectedReview={selectedReview}
            companyName={companyName}
            setReviewDetails={setReviewDetails}
          />
        )}
      </div>
    </div>
  );
};

export default Reviews;
