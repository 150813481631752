// Libraries

import React, { useContext } from "react";

// Dependencies

import Reviews from "./Reviews";
import { useToast } from "../../../../components/toast/use-toast.tsx";
import { AuthContext } from "../../../../context/AuthContext";
import { getReviewsByVendor, updateReview } from "./utils.js";
import { ApprovalStatus } from "../../../../constant/approvalStatus.js";

// Public

const ReviewsModal = ({
  setIsReviewModalOpen,
  selectedReview,
  companyName,
  setReviewDetails,
}) => {
  const { user } = useContext(AuthContext);
  const { toast } = useToast();

  const onClose = () => {
    setIsReviewModalOpen(false);
  };

  const handleReviewApproveReject = (approvalStatus) => {
    updateReview(
      selectedReview.reviewId,
      user?.token,
      toast,
      approvalStatus,
      onClose,
      companyName,
      setReviewDetails
    );
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white w-full max-w-4xl p-6 rounded-md overflow-y-auto max-h-[90vh]">
        <div className="flex justify-between items-center">
          <div>
            <h2 className="text-2xl font-bold">Reviews</h2>
            <h3 className="text-xl mt-3 capitalize">{selectedReview?.model}</h3>
          </div>
          <div>
            {selectedReview.approvalStatus === ApprovalStatus.PENDING ? (
              <>
                <button
                  type="button"
                  onClick={() =>
                    handleReviewApproveReject(ApprovalStatus.APPROVED)
                  }
                  className="p-2 border border-black bg-black rounded-md text-white mt-4 mr-3"
                >
                  Approve
                </button>
                <button
                  type="button"
                  onClick={() =>
                    handleReviewApproveReject(ApprovalStatus.REJECTED)
                  }
                  className="p-2 border border-black bg-white rounded-md text-black mt-4 mr-5"
                >
                  Reject
                </button>
              </>
            ) : selectedReview.approvalStatus === ApprovalStatus.APPROVED ? (
              <button
                type="button"
                onClick={() =>
                  handleReviewApproveReject(ApprovalStatus.REJECTED)
                }
                className="p-2 border border-black bg-white rounded-md text-black mt-4 mr-5"
              >
                Reject
              </button>
            ) : (
              <button
                type="button"
                onClick={() =>
                  handleReviewApproveReject(ApprovalStatus.APPROVED)
                }
                className="p-2 border border-black bg-black rounded-md text-white mt-4 mr-3"
              >
                Approve
              </button>
            )}
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-800 font-bold text-2xl"
            >
              ×
            </button>
          </div>
        </div>
        <Reviews
          answerText={selectedReview.answerC}
          userName={selectedReview.fullName}
          created={selectedReview.created}
          review={selectedReview}
        />
      </div>
    </div>
  );
};

export default ReviewsModal;
