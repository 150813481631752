import React, { useEffect, useState } from 'react'
import {useParams} from "react-router-dom";
import { ModelSelectors } from './components/combobox';
import EditForm from './components/editForm';
import { useNavigate } from "react-router-dom";
import DeleteModel from './components/deleteModel';

const EditModel = () => {
  const [companyModels, setCompanyModels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [submodels,setSubModels] = useState(null);
  const [selectedModel,setSelectedModel] = useState(null);
  const [selectedSubModel,setSelectedSubModel] = useState(null);
  const [modelDetails, setModelDetails] = useState(null)
  const { companyId, companyName } = useParams();
  const navigate = useNavigate();

  async function fetchModelNames(brandModel) {
    try {
      const response = await fetch(`https://evmainbackend.theevshowroom.com/api/getallmodelnamebycategory/${brandModel}`, {
        method: "GET", 
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          Cookie: 'connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E',
        },
      });
  
      const dataResponse = await response.json();
      if (dataResponse && dataResponse.status) {
        setCompanyModels(dataResponse.data);
        setSelectedModel(dataResponse.data[0]);
        setSubModels(dataResponse.data.length ? dataResponse.data[0]?.subModel : null);
        setSelectedSubModel(null);
        // setSelectedSubModel(dataResponse.data.length && dataResponse.data[0]?.subModel ? dataResponse.data[0]?.subModel[0] : null)
      }
      else setCompanyModels([]);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchModelDetails = async (modelId) => {
    try {
      setIsLoading(true);
      const response = await fetch(`https://evmainbackend.theevshowroom.com/api/getmodeldetail/${modelId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          Cookie: 'connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E',
        },
      });
  
      const dataResponse = await response.json();

      if (dataResponse && dataResponse.status) {
        setModelDetails(dataResponse.data);
      }
      else {
        setModelDetails(null);
      }

      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }
  }

  const handleModelChange = (modelId) => {
    const requiredModel = companyModels.find(model => model.id.toString() === modelId.toString());
    setSubModels(requiredModel?.subModel)
    setSelectedModel(requiredModel);
    setSelectedSubModel(null);
  };

  const handleSubmodelChange = (subModelId) => {
    if(subModelId === 'none'){
      setSelectedSubModel(null);
      return;
    }
    const requiredModel = submodels.find(model => model.id.toString() === subModelId.toString())
    setSelectedSubModel(requiredModel);
  };

  useEffect(()=>{
    if(companyId)fetchModelNames(companyId)
  },[companyId]);

  useEffect(()=>{
    if(selectedSubModel){
      fetchModelDetails(selectedSubModel.id);
    }
    else if(selectedModel){
      fetchModelDetails(selectedModel.id);
    }
  },[selectedModel,selectedSubModel])

  return (
    <div>
      <div className='flex justify-between px-8'>
        <h2 className='uppercase font-semibold text-xl leading-5 py-2'>{companyName}</h2>
        <div className='my-2'>
          <button
            type="button"
            onClick={() => navigate(`/admin/products/${companyId}/${companyName}/new-model`)}
            className='p-2 border rounded-md bg-black text-white'
          >
            Add new model
          </button>
          <button
            type="button"
            onClick={() => navigate(`/admin/products/${companyId}/${companyName}/${selectedModel?.id}/new-submodel`)}
            className='p-2 border border-black rounded-md ml-2'
          >
            Add new Sub-model
          </button>
          <DeleteModel
            selectedModelId={selectedSubModel?.modelId ? selectedSubModel?.modelId : selectedModel?.modelId}
            selectedModelName={selectedSubModel?.model ? selectedSubModel?.model : selectedModel?.model}
            onClose={() => fetchModelNames(companyId)}
          />
          <button
            type="button"
            onClick={() => navigate(`/admin/${companyId}/${companyName}/edit-profile`)}
            className='p-2 border border-black rounded-md ml-2'
          >
            Edit Profile
          </button>
          <button
            type="button"
            onClick={() => navigate(`/admin/${companyId}/${companyName}/reviews`)}
            className='p-2 border border-black rounded-md ml-2'
          >
            Reviews
          </button>
          {/* <button
            type="button"
            onClick={() => deleteModel(selectedModel?.modelId ? selectedModel?.modelId : selectedSubModel?.modelId)}
            className='p-2'
          >
            <Trash size={24} />
          </button> */}
        </div>
      </div>
      <ModelSelectors
        modelsList={companyModels}
        subModelsList={submodels}
        handleModelChange={handleModelChange}
        handleSubmodelChange={handleSubmodelChange}
        selectedModel={selectedModel}
        selectedSubModel={selectedSubModel}
      />
      {modelDetails && !isLoading && <EditForm modelDetails={modelDetails} modelNames={companyModels}/>}
    </div>
  )
}

export default EditModel
