import React from 'react'
import { Formik } from 'formik';

const General = ({
  initialValues,
  handleSubmit,
  isUploading
}) => {
  return (
    <Formik
    enableReinitialize
    initialValues={initialValues}
    validate={(values) => {
      const errors = {};
      return errors;
    }}
    onSubmit={handleSubmit}
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      isSubmitting,
    }) => (
      <form onSubmit={handleSubmit} className='space-y-6 max-w-4xl'>
        <div>
          <label className='block text-sm font-medium text-gray-700'>Company Logo</label>
          <div className='mt-2 relative flex items-center'>
            <input
              type='file'
              name='companyLogo'
              onChange={(event) => {
                setFieldValue('companyLogo', event.currentTarget.files[0]);
              }}
              onBlur={handleBlur}
              disabled={isUploading}
              className='block w-full text-sm text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 focus:outline-none'
            />
            {isUploading && (
              <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 rounded-lg">
                <svg className="animate-spin h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                </svg>
              </div>
            )}
          </div>
          {errors.companyLogo && touched.companyLogo && (
            <div className='text-red-600 text-sm mt-1'>{errors.companyLogo}</div>
          )}
        </div>
        <div>
          <label className='block text-sm font-medium text-gray-700'>Company Thumbnail</label>
          <div className='mt-2 relative flex items-center'>
            <input
              type='file'
              name='companyThumbnail'
              onChange={(event) => {
                setFieldValue('companyThumbnail', event.currentTarget.files[0]);
              }}
              onBlur={handleBlur}
              disabled={isUploading}
              className='block w-full text-sm text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 focus:outline-none'
            />
            {isUploading && (
              <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 rounded-lg">
                <svg className="animate-spin h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                </svg>
              </div>
            )}
          </div>
          {errors.companyThumbnail && touched.companyThumbnail && (
            <div className='text-red-600 text-sm mt-1'>{errors.companyThumbnail}</div>
          )}
        </div>
        <div>
          <label className='block text-sm font-medium text-gray-700'>Page Text</label>
          <textarea
            name='pageText'
            value={values.pageText}
            onChange={handleChange}
            onBlur={handleBlur}
            rows='4'
            className='block w-full text-sm text-gray-900 border border-gray-300 rounded-sm bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 p-2'
          ></textarea>
          {errors.pageText && touched.pageText && (
            <div className='text-red-600 text-sm mt-1'>{errors.pageText}</div>
          )}
        </div>
        <div className='flex justify-center'>
          <button
            type='submit'
            disabled={isSubmitting || isUploading}
            className='px-6 py-3 bg-blue-600 text-white rounded-lg text-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
          >
            Submit
          </button>
        </div>
      </form>
    )}
  </Formik>
  )
}

export default General
