import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { useToast } from "../../../components/toast/use-toast.tsx";
import { VendorSelector } from "./components/vendorSelector.js";
import { ModelSelector } from "./components/modelSelector.js";

const MobileSlidersPage = () => {
  const { user } = useContext(AuthContext);
  const { toast } = useToast();
  const navigate = useNavigate();

  const [vendors, setVendors] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedImage, setSelectedImage] = useState({});

  const fetchSliders = async () => {
    try {
      const response = await fetch(
        `https://evmainbackend.theevshowroom.com/api/getMobileSliders?token=${user?.token}`,
        {
          method: "GET",
          headers: {
            Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          },
        }
      );
      const data = await response.json();
      if (response.ok && data.success) {
        setSliders(data.sliders);
      } else {
        throw new Error(data.message || "Failed to fetch sliders");
      }
    } catch (error) {
      console.error("Error fetching sliders:", error);
      toast({
        title: "Error",
        description: "Failed to fetch sliders",
        status: "error",
      });
    }
  };

  const fetchVendorsWithModels = async () => {
    try {
      const response = await fetch(
        `https://evmainbackend.theevshowroom.com/api/getVendorsWithModels?token=${user?.token}`,
        {
          method: "GET",
          headers: {
            Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          },
        }
      );
      const data = await response.json();
      if (response.ok && data.success) {
        setVendors(data.data);
      } else {
        throw new Error(data.message || "Failed to fetch vendors and models");
      }
    } catch (error) {
      console.error("Error fetching vendors and models:", error);
      toast({
        title: "Error",
        description: "Failed to fetch vendors and models",
        status: "error",
      });
    }
  };

  const handleVendorChange = (sliderId, vendorId) => {
    setSliders((prev) =>
      prev.map((slider) =>
        slider.id === sliderId ? { ...slider, vendorId, modelSlug: null } : slider
      )
    );
  };

  const handleModelChange = (sliderId, modelId) => {
    setSliders((prev) =>
      prev.map((slider) =>
        slider.id === sliderId ? { ...slider, modelSlug: modelId } : slider
      )
    );
  };

  const handleImageChange = (id, file) => {
    setSelectedImage((prev) => ({ ...prev, [id]: file }));
  };

  const handleImageUpload = async (id) => {
    const file = selectedImage[id];
    if (!file) {
      toast({
        title: "Error",
        description: "Please select an image to upload",
        status: "error",
      });
      return;
    }

    const formData = new FormData();
    formData.append("sliderImage", file);
    formData.append("id", id);

    setIsUploading(true);
    try {
      const response = await fetch(
        `https://evmainbackend.theevshowroom.com/api/updateMobileSliderImage?token=${user?.token}`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || "Image update failed");
      }

      toast({
        title: "Image Updated",
        description: "Slider image updated successfully",
      });
      setSelectedImage((prev) => ({ ...prev, [id]: null }));
      await fetchSliders();
    } catch (error) {
      console.error("Error uploading image:", error);
      toast({
        title: "Error",
        description: "Image update failed",
        status: "error",
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleDeleteSlider = async (id) => {
    setIsUploading(true);
    try {
      const response = await fetch(
        `https://evmainbackend.theevshowroom.com/api/deleteMobileSlider/${id}?token=${user?.token}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || "Failed to delete slider");
      }

      toast({
        title: "Slider Deleted",
        description: "Slider deleted successfully",
      });
      await fetchSliders();
    } catch (error) {
      console.error("Error deleting slider:", error);
      toast({
        title: "Error",
        description: "Failed to delete slider",
        status: "error",
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `https://evmainbackend.theevshowroom.com/api/updateMobileSliders?token=${user?.token}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          },
          body: JSON.stringify({ sliders }),
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || "Failed to update sliders");
      }

      toast({
        title: "Sliders Updated",
        description: "All sliders updated successfully",
      });
      await fetchSliders();
    } catch (error) {
      console.error("Error updating sliders:", error);
      toast({
        title: "Error",
        description: "Failed to update sliders",
        status: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchVendorsWithModels();
    fetchSliders();
  }, []);

  return (
    <div className="w-full p-4">
      <div className="flex justify-between items-center">
        <button
          onClick={() => navigate("/admin/mobile-sliders/new-mobile-slider")}
          className="px-4 py-2 ml-auto bg-black text-white rounded-lg text-sm font-medium hover:bg-gray-800 focus:outline-none focus:ring focus:ring-gray-500"
        >
          Add Slider
        </button>
      </div>
      <div className="space-y-6">
        {sliders.map((slider) => (
          <div key={slider.id} className="flex items-center space-x-6">
            <div className="min-h-[440px] max-h-[440px] overflow-hidden">
              <img
                src={slider.imageUrl}
                alt="Slider"
                className="object-cover w-full h-[440px] rounded border border-gray-300"
              />
            </div>
            <div className="flex-[0.75] space-y-2">
              <VendorSelector
                vendors={vendors}
                selectedVendor={slider.vendorId}
                onSelect={(vendorId) => handleVendorChange(slider.id, vendorId)}
                disabled={isUploading || isSubmitting}
              />
              <ModelSelector
                models={
                  vendors.find((vendor) => vendor.vendorId === slider.vendorId)
                    ?.models || []
                }
                selectedModel={slider.modelSlug}
                onSelect={(modelId) => handleModelChange(slider.id, modelId)}
                disabled={isUploading || isSubmitting}
              />
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Priority Order
                </label>
                <input
                  type="number"
                  value={slider.priority_order}
                  onChange={(e) =>
                    setSliders((prev) =>
                      prev.map((s) =>
                        s.id === slider.id
                          ? { ...s, priority_order: e.target.value }
                          : s
                      )
                    )
                  }
                  disabled={isUploading || isSubmitting}
                  className="block border w-full text-sm border-black rounded-sm p-1 pl-2"
                  onWheel={(e) => e.currentTarget.blur()}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Upload Image</label>
                <input
                  type="file"
                  onChange={(e) => handleImageChange(slider.id, e.target.files[0])}
                  disabled={isUploading || isSubmitting}
                  className="block w-full text-sm border-black rounded-sm cursor-pointer focus:ring focus:ring-gray-400"
                />
              </div>
              <div className="flex space-x-4 mt-4">
                <button
                  onClick={() => handleImageUpload(slider.id)}
                  disabled={isUploading || isSubmitting}
                  className="px-4 py-2 bg-black text-white rounded-lg text-sm font-medium hover:bg-gray-800 focus:outline-none focus:ring focus:ring-gray-500"
                >
                  Update Image
                </button>
                <button
                  onClick={() => handleDeleteSlider(slider.id)}
                  disabled={isUploading || isSubmitting}
                  className="px-4 py-2 bg-red-500 text-white rounded-lg text-sm font-medium hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-300"
                >
                  Delete Slider
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 text-center">
        <button
          onClick={handleSubmit}
          disabled={isUploading || isSubmitting}
          className="px-6 py-3 bg-black text-white rounded-lg text-lg font-medium hover:bg-gray-800 focus:outline-none focus:ring focus:ring-gray-500"
        >
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default MobileSlidersPage;
