import React, { useEffect, useState, useContext } from 'react';
import { Formik } from 'formik';
import { AuthContext } from "../../../../context/AuthContext";
import { useToast } from "../../../../components/toast/use-toast.tsx";

const VendorFaqs = ({ vendorId, vendorName }) => {
  const [faqs, setFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const { toast } = useToast()

  // Fetch FAQs
  const fetchFaqs = async () => {
    try {
      const response = await fetch(`https://evmainbackend.theevshowroom.com/api/getVendorFaqs/${vendorName}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
        },
      });
      const data = await response.json();
      if (data.success) {
        setFaqs(data.faqs);
      } else {
        console.error('Failed to fetch FAQs:', data.message);
      }
    } catch (error) {
      console.error('Error fetching FAQs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Add new FAQ
  const handleAddFaq = async () => {
    try {
      const response = await fetch(`https://evmainbackend.theevshowroom.com/api/addVendorFaq?token=${user?.token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
        },
        body: JSON.stringify({ vendorId }),
      });
      const data = await response.json();
      if (data.success) {
        toast({
          title: "FAQs added",
          description: "FAQs added successfully",
        });
        fetchFaqs(); // Refresh FAQs
      } else {
        console.error('Failed to add FAQ:', data.message);
      }
    } catch (error) {
      console.error('Error adding FAQ:', error);
    }
  };

  // Submit updated FAQs
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const response = await fetch(`https://evmainbackend.theevshowroom.com/api/updateVendorFaqs?token=${user?.token}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
        },
        body: JSON.stringify({ faqs: values.faqs }),
      });
      const data = await response.json();
      if (data.success) {
        toast({
          title: "FAQs updated",
          description: "FAQs updated successfully",
        });
        fetchFaqs(); // Refresh FAQs
      } else {
        console.error('Failed to update FAQs:', data.message);
      }
    } catch (error) {
      console.error('Error updating FAQs:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteFAQ = async (id) => {
    try {
      const response = await fetch(
        `https://evmainbackend.theevshowroom.com/api/deleteVendorFaq/${id}?token=${user?.token}`,
        {
          method: "DELETE",
          headers: {
            Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || "Failed to delete FAQ");
      }

      toast({
        title: "FAQ Deleted",
        description: "FAQ deleted successfully",
      });
      await fetchFaqs();
    } catch (error) {
      console.error("Error deleting FAQ:", error);
      toast({
        title: "Error",
        description: "Failed to delete FAQ",
        status: "error",
      });
    } finally {
    }
  };


  useEffect(() => {
    fetchFaqs();
  }, []);

  return (
    <div className="p-4 max-w-4xl mx-auto">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{ faqs }}
          validate={(values) => {
            const errors = {};
            if (!values.faqs || values.faqs.length === 0) {
              errors.faqs = 'FAQs cannot be empty.';
            }
            return errors;
          }}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className="space-y-6">
              {values.faqs.map((faq, index) => (
                <div key={faq.id} className="border p-4 rounded-md">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Question</label>
                    <input
                      type="text"
                      name={`faqs[${index}].question`}
                      value={faq.question}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-sm p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm font-medium text-gray-700">Answer</label>
                    <textarea
                      name={`faqs[${index}].answer`}
                      value={faq.answer}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      rows="3"
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-sm p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    ></textarea>
                  </div>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      handleDeleteFAQ(faq.id);
                    }}
                    disabled={isSubmitting}
                    className="px-4 py-2 mt-4 bg-black text-white rounded-lg text-sm font-medium hover:bg-red-600 focus:outline-none focus:ring focus:ring-red-300"
                  >
                    Delete FAQ
                  </button>
                </div>
              ))}
              <div className="flex justify-center mt-6 gap-4">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddFaq();
                  }}
                  className="p-3 bg-black text-white rounded-lg text-lg font-medium hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                >
                  Add FAQ
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="p-3 bg-black text-white rounded-lg text-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default VendorFaqs;
