import { ApprovalStatus } from "../../../../constant/approvalStatus";

export const getReviewsByVendor = async (vendor, setReviewDetails) => {
  try {
    const api = `https://evmainbackend.theevshowroom.com/api/getReviewsByVendor/${vendor}`;

    const response = await fetch(api, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
        Cookie:
          "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
      },
      next: { revalidate: 0 },
    });

    const responseData = await response.json();

    setReviewDetails(responseData?.data);
  } catch (error) {
    console.log("Error while fetching data", error);
  }
};

export const updateReview = async (
  reviewId,
  token,
  toast,
  approvalStatus,
  onClose,
  companyName,
  setReviewDetails
) => {
  try {
    const api = `https://evmainbackend.theevshowroom.com/api/updateReview/${reviewId}`;

    const response = await fetch(api, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
        token: token,
      },
      body: JSON.stringify({ approvalStatus }),
    });

    if (!response.ok) {
      throw new Error("Error while updating review");
    }

    approvalStatus === ApprovalStatus.APPROVED
      ? toast({
          title: "Review Approved",
          description: "Review has been approved successfully.",
        })
      : toast({
          title: "Review Rejected",
          description: "Review has been rejected.",
        });

    onClose();
    getReviewsByVendor(companyName, setReviewDetails);
  } catch (err) {
    console.log("Error while updating review", err);
  }
};
